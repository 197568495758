import './App.css';
import Navbar from './Navbar';
import 'bulma';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ValueProposition from './ValueProposition';
import SocialProof from './SocialProof';
import ContactInfo from './ContactInfo';
import Footer from './Footer';
import PrivacyPage from './PrivacyPage';
import TermsAndConditions from './TermsAndConditions';

const HomePage = () => (
  <>
    <ValueProposition />
    {/* <SocialProof /> */}
    <ContactInfo />
  </>
)

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <div id="router">
          {/* <Navbar /> */}
            <Switch>
              <Route strict path="/privacy" component={PrivacyPage} />
              <Route strict path="/terms" component={TermsAndConditions} />
              <Route strict path="/" component={HomePage} />
            </Switch>
          <Footer />
          </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
