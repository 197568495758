// import Logo from './images/valueProp.jpeg';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Grid, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Microlink from "@microlink/react";
import Navbar from './Navbar';
import './style.scss'
// import background from './images/valueProp.jpeg'
import vivovideo from './videos/VivoSurgeryValuePropClip.mp4'
import vivologo1 from './images/vivo-icon-1.png'
import vivologo2 from './images/vivo-icon-2.png'
import vivologo3 from './images/vivo-icon-3.png'
import vivologo4 from './images/vivo-icon-4.png'
import vivologo5 from './images/vivo-icon-5.png'
import vivologo6 from './images/vivo-icon-6.png'
import newsArticle1 from './images/news-article-1.jpg'
import newsArticle2 from './images/news-article-2.jpg'
import newsArticle3 from './images/news-article-3.jpg'
import plusIcon from './images/plus-icon.svg'
import { useEffect, useState } from 'react';
import { useOrientation } from "@uidotdev/usehooks";
// import vivologo7 from './images/vivo-icon-7.svg'
// import NavItem from './NavItem';

function ValueProposition(props) {
  const theme = useTheme();
  const orientation = useOrientation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xl"))

  useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1200;
          if (ismobile !== isMobile) setIsMobile(ismobile);
          // const screenorientation = useScreenOrientation();
          // if (screenorientation !== screenOrientation) setScreenOrientation(screenorientation);
      }, false);
  }, [isMobile]);

  return (
    <>
      {/* <section style={{minHeight:  isExtraSmall ? '50em': '83em', background: 'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 10%)'}}> */}
      <section style={{minHeight:  isExtraSmall ? (orientation.type.toLocaleLowerCase() === 'landscape-primary' ? '58em' :'64em') : '80em' }}>
        <div 
          style={{ overflow: 'hidden' }}
        >
            <video
              muted
              src={vivovideo}
              autoPlay
              loop
              playsInline
              style={{
                objectFit: 'cover',
                filter: 'brightness(0.9)',
                position: 'absolute',
                zIndex: '-1',
                left: '0',
                overflow: 'hidden',
                width: '100%',
                height: isMobile ? (orientation.type.toLocaleLowerCase() === 'landscape-primary' ? '120%' :'80%') : '110%',
                marginTop: isMobile ? (orientation.type.toLocaleLowerCase() === 'landscape-primary' ? '30em' :'34em') : '20em',
            }}
            />
            <section className="hero is-medium is-mobile">
              <Navbar />
              {/* <div className="hero-body">
                  <div style={{marginTop: "4.5em", width: "100%", textAlign: 'center'}}>
                      <Typography
                        style={{ color: 'white', fontWeight: '500' }}
                        sx={{
                          typography: { sm: "h2", xs: "h3", md: "h1", lg: "h1", xl: "h1"}
                        }}
                      >
                          Remotely access live surgical expertise.
                      </Typography>
                  <Button
                    variant="outlined"
                    size={isExtraSmall ? "medium" : "large"}
                    style={{
                      background: '#26b0c5',
                      border: '#26b0c5',
                        marginTop: '3em',
                        marginRight: '1em',
                        color: 'white',
                    }}
                    data-target="contact-us-"
                    href="https://calendly.com/phillip_mohabir/30min?month=2023-11"
                    target="_blank"
                  >
                    Book a meeting
                  </Button>
                  <Button
                      variant="outlined"
                      size={isExtraSmall ? "medium" : "large"}
                      style={{
                        background: 'white',
                        border: 'white',
                        marginTop: '3em',
                        borderColor: '#26b0c5',
                        color: '#26b0c5',
                      }}
                      data-target="log-in"
                      href="https://vivosurgery.io/landing/login"
                  >
                    Go to Dashboard
                  </Button>
                </div>
                </div> */}
            </section>
        </div>
      </section>
        {(!isMobile) && (
        <section className='section' style={{background: "black", height: '33em'}}>
          <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item xs={3}>
              <img
                src={vivologo1}
                alt="Vivosurgery Logo"
                width="40%"
                style={{marginLeft: '25%'}}
                // style={{ marginTop: '1em', marginRight: '15em', marginBottom: '1em',}}
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={vivologo2}
                alt="Vivosurgery Logo"
                width="40%"
                style={{marginLeft: '25%'}}
                // style={{ marginTop: '1em', marginRight: '15em', marginBottom: '1em'}}
              />    
            </Grid>
            <Grid item xs={3}>
              <img
                src={vivologo3}
                alt="Vivosurgery Logo"
                width="40%"
                style={{marginLeft: '25%'}}
                // style={{ marginTop: '1em', marginRight: '15em', marginBottom: '1em'}}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item xs={3}>
              <Typography 
              marginBottom={isExtraSmall ? '1.5em': '0.5em'}
              fontSize={'2em'} color={'white'}
              fontWeight={'400'}
              align='center'
              >
                Turnkey Service
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize={'2em'} color={'white'} fontWeight={'400'} align="center">Unparalleled Access</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize={'2em'} color={'white'} fontWeight={'400'} align="center">End-to End Deployment</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item xs={3}>
              <Typography align="left" color={'white'}>Leverage our network of 100+ surgeons to build custom shadowing opportunities</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left" color={'white'}>50 remote learners can attend simultaneously, with two-way communication, and HD multi-view</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left" color={'white'}><br/>Dedicated clinical reps support  your program with surgical planning, session moderation, and engagement tracking</Typography>
            </Grid>
          </Grid>
        </section>
        )}
        {(isMobile) && (
        <section className='section' style={{background: "black", height: '65em'}}>
          <Grid container direction="column" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item xs={3} align='left'>
              <img
                src={vivologo1}
                alt="Vivosurgery Logo"
                width="30%"
              />
              <Typography 
                marginBottom={'0.5em'}
                fontSize={'2em'}
                color={'white'}
                fontWeight={'350'}
                align='left'
              >
                Turnkey Service
              </Typography>
              <Typography
                align="left"
                color={'white'}
              >
                Leverage our network of 100+ surgeons to build custom shadowing opportunities
              </Typography>
            </Grid>
            <Grid item xs={3} align='left'>
              <img
                src={vivologo2}
                alt="Vivosurgery Logo"
                width="30%"
              />
              <Typography
                marginBottom={'0.5em'}
                fontSize={'2em'}
                color={'white'}
                fontWeight={'350'}
                align="left"
              >
                Unparalleled Access
              </Typography>
              <Typography
                align="left"
                color={'white'}
              >
                50 remote learners can attend simultaneously, with two-way communication, and HD multi-view
              </Typography>
            </Grid>
            <Grid item xs={3} align='left'>
              <img
                src={vivologo3}
                alt="Vivosurgery Logo"
                width="30%"
              />
              <Typography
                marginBottom={'0.5em'}
                fontSize={'2em'}
                color={'white'}
                fontWeight={'350'}
                align="left"
              >
                End-to End Deployment
              </Typography>
              <Typography
                align="left"
                color={'white'}
              >
                Dedicated clinical reps support  your program with surgical planning, session moderation, and engagement tracking
              </Typography>
            </Grid>
          </Grid>

          <Button
            variant="contiained"
            style={{ background: "white", marginTop: "4em", width: '12em', color: 'black'}}
            href="https://vivosurgery.io/landing/login"
          >
            <Typography fontWeight={'500'}>
              Discover
              <i className="fa fa-arrow-right ml-1"></i>
            </Typography>
          </Button>
        </section>
        )}
      <section className='section'>
        <Container maxWidth="lg">
          <Typography
            marginBottom={'1em'}
            variant={isMobile ? 'h5' : 'h2'}
            align='center'
          >
            We Are Trusted By
          </Typography>
          {!isMobile && (
            <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
              <Grid item xs={4}>
                <img
                  src={vivologo4}
                  alt="Vivosurgery Logo"
                  width="80%"
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  src={vivologo5}
                  alt="Vivosurgery Logo"
                  width="80%"
                />    
              </Grid>
              <Grid item xs={4}>
                <img
                  src={vivologo6}
                  alt="Vivosurgery Logo"
                  width="70%"
                />   
              </Grid>
            </Grid>

          )}
          {isMobile && (
            <div
              style={{
                overflow: 'auto',
                whiteSpace: 'nowrap',
              }}>
              <img
                src={vivologo4}
                alt="Vivosurgery Logo"
                style={{width: '100%', padding: '10%', marginBottom: '30%'}}
              />
              <img
                src={vivologo5}
                alt="Vivosurgery Logo"
                style={{width: '100%', padding: '10%', marginBottom: '25%'}}
                // style={{width: '80%'}}
                // width="10%"
              />
              <img
                src={vivologo6}
                alt="Vivosurgery Logo"
                style={{width: '80%', padding: '10%', marginBottom: '0%'}}
                // style={{width: '80%'}}
                // width="10%"
              />
            </div>
            // <ImageList
            //   cols={3}
            //   // rowHeight={'80%'}
            //   sx={{
            //     gridAutoFlow: 'column',
            //     gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
            //     gridAutoColumns: "minmax(10px, 1fr)"         
            //   }}
            //   // sx={{flexWrap: 'nowrap', transform: 'translateZ(0)', overflowX: 'auto'}}
            //   // sx={{height: 140}}
            // >
            //   <ImageListItem cols={3}>
            //     <img
            //       src={vivologo4}
            //       alt="Vivosurgery Logo"
            //       // style={{width: '80%'}}
            //       // width="10%"
            //     />
            //   </ImageListItem>
            //   <ImageListItem cols={3} sx={{display: 'flex', flexDirection: 'row'}}>
            //     <img
            //       src={vivologo5}
            //       alt="Vivosurgery Logo"
            //       // width="80%"
            //     />
            //   </ImageListItem>
            //   <ImageListItem cols={3} sx={{display: 'flex', flexDirection: 'row'}}>
            //     <img
            //       src={vivologo6}
            //       alt="Vivosurgery Logo"
            //       // width="80%"
            //     />
            //   </ImageListItem>
            // </ImageList>
          )}
        </Container>
      </section>
      {/* <section className="section" style={{marginTop: '1em'}}>
       <Container>
        <Grid container direction="row" spacing={0} sx={{'alignItems': 'left', 'justifyContent': 'left'}}>
            <Grid item xs={2} sx={{'alignItems': 'right', 'justifyContent': 'right'}}>
              <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
                <img
                  src={plusIcon}
                  alt="Vivosurgery Logo"
                  width="40%"
                  // height="10%"
                  style={{ marginTop: '1.5em', marginBottom: '1em'}}
                />
                <Divider orientation='vertical' flexItem sx={{borderRightWidth: 3, borderColor: 'black', marginLeft: '4em'}}/>
              </Box>
            </Grid>
            <Grid item xs={10} >
              <Typography sx={{lineHeight: 1, letterSpacing: 2}} fontSize={'3.3em'} color={'black'} fontWeight={'500'} align="left">Biomedical Engineering programs</Typography>
              <Typography sx={{lineHeight: 1}} align="left" fontSize={'1.5em'} color={'black'}><br/>Gain front-row access to the next frontier of innovation</Typography>
            </Grid>
          </Grid>
        </Container>
      </section> */}
      {/* <section className="section">
        <Container maxWidth="xl">
          <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center', marginLeft: isExtraSmall ? '0': '5em'}}>
            <Grid item xs={6}>
              <Box sx={{height: '25em', width: '30em', background: 'black'}}>
                <Typography fontSize={'7em'} color={'white'} fontWeight={'500'} align="left" marginLeft={'5%'}>230,000</Typography>
                <Typography fontSize={'2em'} color={'white'} fontWeight={'400'} align="left" margin={'0 1em 0 1em'}>
                  <br/>medical device patents filed in the last 3 years.
                </Typography>
              </Box>
          </Grid>
          <Grid item xs={6} >
            <Box sx={{height: '25em', width: '30em', background: 'black'}}>
              <Typography fontSize={'7em'} color={'white'} fontWeight={'400'} align="left" marginLeft={'5%'}>462%</Typography>
              <Typography fontSize={'2em'} color={'white'} fontWeight={'400'} align="left" margin={'0 1em 0 1em'}>
                <br/>increase in surgical patents filed annually.
              </Typography>
            </Box>
          </Grid>
          </Grid>
        </Container>
      </section> */}
      <section className='section' style={{background: "black", height: isMobile ? '90em' : '40em'}}>
      {/* https://metatags.io/ */}
        <Container maxWidth="lg" sx={{margin: '0em auto 5em auto'}}>
        <Typography 
          color={'white'}
          marginBottom={'1em'} 
          variant={isMobile ? 'h4' : 'h2'}
          fontWeight={350}
          align="left"
        >
          Recent News
        </Typography>
        {!isMobile && (
          <Grid container direction="row" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 400, minHeight: 400, maxHeight: 400, border: 2, borderColor: 'white'}}>
              <CardActionArea href='https://globalnews.ca/news/10566897/ontario-surgery-technology-hamilton/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle1}
                  title="article1"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    Ontario technology teaching surgeries worldwide including war-torn Ukraine  | Globalnews.ca
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    A surgical team in Hamilton, Ont. showed off the power of live, interactive teaching platform by reaching doctors 8,000 kilomteres away during a facial reconstruction procedure.
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>globalnews.ca</Typography>
                </CardContent>
              </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} >
              <Card sx={{ maxWidth: 400, minHeight: 400, maxHeight: 400, border: 2, borderColor: 'white'}}>
              <CardActionArea href='https://www.oha.com/news/surgeon%E2%80%99s-award-winning-technology-teaches-surgical-skills-worldwide/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle2}
                  title="article2"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    Health System News Surgeon’s Award-Winning Technology Teaches Surgical Skills, Worldwide
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    Since launching four years ago, Vivo Surgery has expanded worldwide to teach medical students, residents, fellows and physicians in 30 remote locations including in the United States, Ukraine, the Caribbean, Singapore, Oman, Poland, Switzerland and England, giving learners opportunities to view leading-edge...
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>oha.com</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>
            <Grid item xs={4} >
              <Card sx={{ maxWidth: 400, minHeight: 400, maxHeight: 400, border: 2, borderColor: 'white'}}>
              <CardActionArea href='https://www.hamiltonhealthsciences.ca/research-innovation/research/drive-spark-funding-grant/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle3}
                  title="article2"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    DRIVE (SPARK Funding Grant) - Hamilton Health Sciences
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    HHS DRIVE is a platform designed for our people, to help our community of health-care providers, staff and patients. We are dedicated to accelerating the translation of innovative ideas into tangible solutions that improve patient outcomes and advance health care. We are committed to fostering...
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>hamiltonhealthsciences.ca</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid container direction="column" spacing={6} sx={{'alignItems': 'center', 'justifyContent': 'center'}}>
            <Grid item>
              <Card
                sx={{
                  maxWidth: 300,
                  minHeight: 400,
                  maxHeight: 400,
                  border: 2,
                  borderColor: 'white'
                }}
              >
              <CardActionArea href='https://globalnews.ca/news/10566897/ontario-surgery-technology-hamilton/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle1}
                  title="article1"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    Ontario technology teaching surgeries worldwide including war-torn Ukraine  | Globalnews.ca
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    A surgical team in Hamilton, Ont. showed off the power of live, interactive teaching platform by reaching doctors 8,000 kilomteres away during a facial reconstruction procedure.
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>globalnews.ca</Typography>
                </CardContent>
              </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} >
              <Card
                  sx={{
                    maxWidth: 300,
                    minHeight: 400,
                    maxHeight: 400,
                    border: 2,
                    borderColor: 'white'
                  }}
              >
              <CardActionArea href='https://www.oha.com/news/surgeon%E2%80%99s-award-winning-technology-teaches-surgical-skills-worldwide/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle2}
                  title="article2"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    Health System News Surgeon’s Award-Winning Technology Teaches Surgical Skills, Worldwide
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    Since launching four years ago, Vivo Surgery has expanded worldwide to teach medical students, residents, fellows and physicians in 30 remote locations including in the United States, Ukraine, the Caribbean, Singapore, Oman, Poland, Switzerland and England, giving...
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>oha.com</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>
            <Grid item xs={4} >
              <Card
                  sx={{
                    maxWidth: 300,
                    minHeight: 400,
                    maxHeight: 400,
                    border: 2,
                    borderColor: 'white'
                  }}
                >
                <CardActionArea href='https://www.hamiltonhealthsciences.ca/research-innovation/research/drive-spark-funding-grant/'>
                <CardMedia
                  sx={{ height: 160 }}
                  image={newsArticle3}
                  title="article2"
                />
                <CardContent>
                  <Typography noWrap gutterBottom fontSize={'1.1em'} fontWeight={600} component="div">
                    DRIVE (SPARK Funding Grant) - Hamilton Health Sciences
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} align='left'>
                    HHS DRIVE is a platform designed for our people, to help our community of health-care providers, staff and patients. We are dedicated to accelerating the translation of innovative ideas into tangible solutions that improve patient outcomes and advance health care. We are...
                  </Typography>
                  <Typography sx={{ marginTop: '1em', color: '#8899a6'}} align='left'>hamiltonhealthsciences.ca</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          </Grid>
        )}
        </Container>
      </section>
    </>
  )
}

export default ValueProposition;